<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useHttp from '@/comp-functions/useHttp'
import { ref, onMounted } from '@vue/composition-api'
import router from "@/router"

export default {
  page: {
    title: "Edit Vendor",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader
  },
   props:{
      vendor_id: String,
  },
  data() {
    return {
      title: "Edit Vendor",
      defaultNull: null
    };
  },
  setup (props) {
     const { $get } = useHttp()
     const { $put } = useHttp()

      let pVendorId = ''
    if(typeof props.vendor_id !== 'undefined'){
      pVendorId = props.vendor_id
    }

    const getVendor = async () => {
       const {data}  = await $get({ url: 'master/vendor_detail/' + form.value.vendorId})
       form.value.name = data.name
       form.value.address = data.address
       form.value.contactName = data.contact_name
       form.value.contactPhone = data.contact_phone
       form.value.vndCapacitydef = data.vnd_capacitydef
    }

    const form = ref({
      vendorId : pVendorId,
      name: '',
      address:'',
      contactName:'',
      contactPhone:'',
      vndCapacitydef:''
      
      })

      const validationForm = async () => {

          $put({
        url: 'master/vendor/vendors-edit',
        data: form.value
      })

      router.push({ name: 'appsmd-vendor-list' })

      }

      onMounted(() => {
     getVendor()
    })

    return {
      form,
      validationForm,
      getVendor
    }  
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form class="form-horizontal" role="form">
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Name"
                    label-for="vendor_name"
                  >
                  <b-form-input id="" name="" value="" v-model="form.name"></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Address"
                    label-for="vendor_address"
                  >
                  <b-form-input id="" address="" value="" v-model="form.address"></b-form-input>
                  </b-form-group>


                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Contact Name"
                    label-for="vendor_contactname"
                  >
                  <b-form-input id="" contactName="" value="" v-model="form.contactName"></b-form-input>
                  </b-form-group>
                  
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Contact Phone"
                    label-for="vendor_contactphone"
                  >
                                   <b-form-input id="" contactPhone="" value="" v-model="form.contactPhone"></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Load"
                    label-for="vendor_capacity"
                  >
                                   <b-form-input id="" vndCapacitydef="" value="" v-model="form.vndCapacitydef"></b-form-input>
                  </b-form-group>
                  
                </form>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <div class="button-items">
                  <b-button variant="primary" @click.prevent="validationForm">
                    <i class="ri-save-line align-middle ml-2"></i>
                    Submit
                  </b-button>
                  <b-button variant="danger" :to="{ name: 'appsmd-vendor-list'}">
                    <i class="ri-arrow-left-line align-middle mr-2"></i> Back
                  </b-button>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>